/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    strong: "strong",
    ul: "ul",
    li: "li",
    em: "em",
    code: "code"
  }, _provideComponents(), props.components), {Boss, Skill, Specialization, Effect, Condition, Trait, Boon, Control, SpecialActionKey} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "MAMA",
    video: "KlTuxWA9uKE",
    videoCreator: "Elda [dT]",
    foodId: "43360",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "arcaneblast",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "KlTuxWA9uKE",
    videoCreator: "Elda [dT]",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "5569",
    utility1Id: "5539",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(_components.p, null, "This version is made mostly for PuGs that are not phasing fast enough to get profit from ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), ". If group is good you can follow the organised rotation and just add ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " at start of the boss for cc (it will be up for phase 3). Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for 1 tick in ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " at the beginning of each phase.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go to the boss using ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), ". ", React.createElement(_components.strong, null, "DO NOT!"), " Cast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " because you will get stuck on staff, drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and cast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " where MAMA spawns. Equip your Sword/Dagger and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " before the ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " animation finishes and start the boss!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If any party member provides fire field you can use ", React.createElement(Specialization, {
    name: "Tempest"
  }), " precast as well."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " when boss is just about to become vulnerable to provide cc."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " under the ", React.createElement(Effect, {
    name: "Exposed"
  }), " buff."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Knight 1:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Port and CC the knight with ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " on second add spawn point."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use on ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " on add, precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on boss spawn, when the knight is low on health ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " back to MAMA."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " to be ready for phase 3."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Save your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " for phase 3."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Knight 2:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Port and CC the knight with ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pick up your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and use ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " on add, precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on the boss location when the knight is about half on health, you can support your team with ", React.createElement(Skill, {
    name: "conjurefierygreatsword",
    id: "5516"
  }), " autos if you are slacking with knight DPS."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When the knight is about to die move towards the boss (be careful with damaging fields!)."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " as fast as the boss is vulnerable for quick CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain-> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " to be ready for phase 4."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " under the effect of ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If CC is slow delay your ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " to to get it in ", React.createElement(Effect, {
    name: "exposed"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Knight 3:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Move outside of the toxic field, use ", React.createElement(Skill, {
    id: "5569",
    id: "5569"
  }), " if low on health."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the knight with ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Autoattack add until it's about to die."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " when MAMA is about to jump to cleave knight and boss. ", React.createElement(_components.strong, null, "Dodge inside boss hitbox when the boss is landing to not get knocked")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump over the wave to not get knocked."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If phases are slow you will be able to use ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " again in phase 4 to CC the boss/avoid damage."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    video: "XnTBfLMMic4",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "arcaneblast",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    video: "XnTBfLMMic4",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24658",
    healId: "5569",
    utility1Id: "5539",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(_components.p, null, "This version is made mostly for pugs that are not phasing fast enough to profit from ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " and where you have to solo your add. Use ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " slightly delayed to get more ", React.createElement(Condition, {
    name: "Burning"
  }), " ticks.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " close to the boss hitbox."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If any party member provides fire field you can use tempest precast as well."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " ->", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " -> Pick up ", React.createElement(Skill, {
    name: "Conjurelightninghammer",
    id: "5624"
  }), " -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use one ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " at the beginning to keep it on cooldown."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, React.createElement(_components.em, null, "1st Add"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Leap to your add with ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " and use ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and additional DPS."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use one/two autochains ( depends on boons ) in ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " to lower the health of add and throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " on second add spawn point."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " through the add back to boss."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> autos in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " til ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " is up. Start attuning back to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " during ", React.createElement(Effect, {
    name: "Exposed"
  }), " buff."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, React.createElement(_components.em, null, "2nd Add"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Same like first split, just pick up ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " you dropped at first split if it's still up after phase 2."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), "-> auto chain ->", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> autos til ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " is up again. Just continue loop until boss is dead after that part."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " under ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "dAn5Uy0--hc",
    timestamp: "",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "arcaneblast",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    video: "dAn5Uy0--hc",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24658",
    healId: "5569",
    utility1Id: "5539",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(_components.p, null, "If group DPS is low its recommended to run ", React.createElement(Trait, {
    id: "214",
    id: "214"
  }), " instead of ", React.createElement(Trait, {
    id: "1502",
    id: "1502"
  }), ". In PuGs it is recommended to use the raid rotation from Snow Crows.Use ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " precasted for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ". If phases are long try to use next ones in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " -> ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " to get more burning stacks. Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " during ", React.createElement(Effect, {
    name: "Exposed"
  }), " window.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " for yourself to the boss hitbox and gg/use portal to get back to party. Skip this part if pugs are nervous about elitist stuff."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " You can use tempest precast as well if any party member is placing fire field."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " ( autochain if no LH precast ), ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), "/", React.createElement(Skill, {
    id: "5733",
    id: "5733"
  }), " if breakbar is still not broken -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " ->", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "-> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop with BTTH rotation until boss is phased."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Save your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " after ", React.createElement(Effect, {
    name: "Exposed"
  }), " buff for next phases."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Splits")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For splits you have some time to blast additional ", React.createElement(Boon, {
    name: "Might"
  }), " for yourself and be back on ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " for the incoming phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place a fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " and attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), ". Blast it with ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  }), ", attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and use another blast with ", React.createElement(Skill, {
    id: "5690",
    id: "5690"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), ", attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " just before p2/p3 to get ", React.createElement(Trait, {
    id: "2131",
    id: "2131"
  }), " buff."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Do not hesitate to use ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  }), ", ", React.createElement(Skill, {
    id: "46447",
    id: "46447"
  }), ", ", React.createElement(Skill, {
    id: "44405",
    id: "44405"
  }), " if things are going hot. Just keep in mind that you have to be on ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " just as phase starts."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " if it's up just at the start of phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " -> ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " -> ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> Complete your auto attack chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop with BTTH rotation until boss is phased."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Complete your auto attack chains and get as much damage as you can in the ", React.createElement(Effect, {
    name: "Exposed"
  }), " duration."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " in the middle of boss hitbox."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), "-> ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " ->", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> Autoattack Chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When boss jumps to the corner and bubble phase begins pick up your remaining ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " to it. Cast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on it and continue with standard BTTH rotation til it's dead."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Complete your auto attack chains and get as much damage as you can in the ", React.createElement(Effect, {
    name: "Exposed"
  }), " duration."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "RoixkWVPAaU",
    timestamp: "",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "9443",
    heal: "arcanebrilliance",
    utility1: "primordialstance",
    utility2: "glyphofstorms",
    utility3: "unravel",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "RoixkWVPAaU",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "21656",
    utility1Id: "40183",
    utility2Id: "5734",
    utility3Id: "44612",
    eliteId: "5516"
  }, React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase, if playing ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " spam it during ", React.createElement(Effect, {
    name: "Exposed"
  }), " window.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), ", drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and cast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " where Skorvald spawns. Equip your Sword/Dagger and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " before the ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " animation finishes and start the boss!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Be quick or be stuck on staff. PuGs love to instant pull."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " for instant cc, cast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " while coming back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the CC Bar is not broken when you use ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " then cast ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " so that you might get ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " in the break bar."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Islands")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Stay in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " after phase 1"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " to anomaly, attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " and blast fire field with ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto add until it's dead"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), ", both blast and evade ", React.createElement(Control, {
    name: "Knockback"
  }), " of the wave with ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " on anomaly just after wave. Attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and blast second time with ", React.createElement(Skill, {
    id: "5690",
    id: "5690"
  })), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and use ", React.createElement(Skill, {
    id: "46447",
    id: "46447"
  }), " as fast as possible to provide self stability and don't get knocked"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " on anomaly and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  })), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 4")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Drop fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " as fast as possible and blast it with ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " in corner for your ", React.createElement(Specialization, {
    name: "Soulbeast"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Avoid knocking wave at all cost, you can jump over it, you can dodge it, you can jump over it with ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " ( but it's tricky ). Just don't get cc'ed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go through the portal for phase 2 when anomaly is on about 30-40% of health, if there is no portal use ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " to fasten the rift jump."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE"), " Try to dodge ", React.createElement(Control, {
    name: "Knockback"
  }), " attacks if there is no ", React.createElement(Boon, {
    name: "Stability"
  }), " and you didn't phase the boss fast enough to skip that mechanic."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "FErf2jfVRRM",
    timestamp: "",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "9443",
    heal: "glyphofelementalharmony",
    utility1: "primordialstance",
    utility2: "glyphofstorms",
    utility3: "arcaneblast",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "FErf2jfVRRM",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "5569",
    utility1Id: "40183",
    utility2Id: "5734",
    utility3Id: "5539",
    eliteId: "5516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Play ", React.createElement(Trait, {
    name: "ragingstorm",
    id: "214"
  }), " over ", React.createElement(Trait, {
    name: "stormsoul",
    id: "1502"
  }), "!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " while in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " / ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), ", especially when ", React.createElement(Skill, {
    name: "Onewolfpack",
    id: "45717"
  }), " buff is up."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "/gg"), " if you are doomed ( skull ) and it's impossible to phase before it blows up (PuGs rarely use this strategy)."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Wait a while before boss is about to be pulled and precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), ", drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and cast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " where Artsariiv spawns. Equip your Sword/Dagger and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " before the ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " animation finishes and start the boss!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You can try static/organized opener it works pretty nice here, but be careful with positioning and timers."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump to the corner and precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " if you haven't used it on middle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " -> ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> Complete your auto attack chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop with BTTH rotation until boss is phased."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Sometimes when PuGs are pretty special you can have ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " ready for phase 2, if it's up go for it."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> autochain -> dodge deathwave -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump to boss with ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rota until boss is phased"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Split 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Throw ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " in the middle maybe someone can get use of it."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> autochain -> dodge deathwave -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump to boss with ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rota until boss is dead."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "9pMxn4HtUyI",
    timestamp: "",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "primordialstance",
    utility2: "glyphofstorms",
    utility3: "arcaneblast",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "9pMxn4HtUyI",
    videoCreator: "Elda [dT]",
    foodId: "41569",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "5569",
    utility1Id: "40183",
    utility2Id: "5734",
    utility3Id: "5539",
    eliteId: "5516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase and mini bosses."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " during ", React.createElement(Effect, {
    name: "Exposed"
  }), " window. Use one blast at start of phase 1 and in phase 3 when ", React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  }), " is up."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Try to precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " before boss hitbox to pick it up during eye attack and turn back from boss when picking it up /gg or use portal to get back to ", React.createElement(_components.em, null, "Mistlock Singularity"), ". Skip it if group is nervous about elitist stuff."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start on ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " with your Sword/Dagger and cast ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " to provide a fire field."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Blast it using ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  }), ", then attune to ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and cast ", React.createElement(Skill, {
    id: "5690",
    id: "5690"
  }), ", ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  }), " and ", React.createElement(Skill, {
    id: "5522",
    id: "5522"
  }), " for extra blasts."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5506",
    id: "5506"
  }), " and ", React.createElement(Skill, {
    id: "5635",
    id: "5635"
  }), ", and swap back to ", React.createElement(Skill, {
    id: "5734",
    id: "5734"
  }), " and ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and take ", React.createElement(_components.em, null, "Mistlock Singularity")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " You can use tempest precast as well if any party member is placing fire field"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> pick up LH ( don't get ", React.createElement(Condition, {
    name: "Fear"
  }), "ed ), use auto chain if not precasted -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If boss is still not phased continue with BTTH rota, start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Orb pushing")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Wait until there is a connection between you and orb, dodge inside damaging field autoattack orb to push it towards pylon and use ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " to push it straight to the pylon. Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to come back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you are low on health and cannot push it that way stand in corner and wait till orb comes to you and push it with one precise autoattack"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Use healing skill to get health for push or to regain scholar uptime for phase 2."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Keep an eye on other orbs, try to precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " ( for blasts mostly )"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If group damage is low you can try to fill ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " in here."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " and ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " on mini boss as fast as possible"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " for CC bar."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Try to avoid corruption fields at any cost, they pretty hurts your phase 3."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Depending on previous phase times and ", React.createElement(Boon, {
    name: "Alacrity"
  }), " uptime your ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " could be on cd in that phase. Use it if it's up before ~60% of boss hp."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rotation until boss is phased, try to not cancel anything while using ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " while avoiding marble attack."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Keep an eye on other orbs, try to precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " ( for blasts mostly )"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If group damage is low you can try to fill ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " in here."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Gladiator")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " to provide additional dps on mini boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " and ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " on mini boss, jump over knocking wave."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " in middle of boss hitbox."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When CC bar is up break it with your ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), "+", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " combo."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on boss"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 6")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Push your orb and come back to boss with ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pick up remaining ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), ", pay attention to other orbs, they can be pretty messy in here, so try to not loose your precasted damage by precasting too early."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rotation until boss is dead."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " or Burn Rigorous Certainty to not get hit by deathray."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
